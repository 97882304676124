<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Report'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="date_label"
                      label="Date :" label-for="date">
                      <b-form-input id="date" v-model="form.date" type="date" size="sm" :disabled="verify"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="report_no_label" label="Nomor Laporan :" label-for="report_no"
                      description="Jika kosong akan terisi otomatis dari sistem.">
                      <b-form-input id="report_no" type="text" placeholder="Enter Text..." size="sm"
                        v-model="form.report_no" :disabled="verify" ref="report_no"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="date_label"
                      label="No Notifikasi :" label-for="no_notifikasi">
                      <b-form-input id="no_notifikasi" v-model="form.no_notifikasi" type="text" size="sm"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="no_wo_label"
                      label="No. WO :" label-for="no_wo">
                      <b-form-input id="no_wo" type="text" v-model="form.no_wo" placeholder="Enter Text..." size="sm"
                        ref="no_wo"></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="issued_to_label" label="Tujuan Laporan :" label-for="issued_to">
                      <treeselect size="sm" id="issued_to" v-model="form.issued_to" :multiple="true" :clearable="true"
                        :async="true" valueFormat="label" :load-options="loadOptionsIssued" :disabled="verify"
                        @select="onSelect" />
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="eksekutor_label" label="Eksekutor :" label-for="eksekutor">
                      <b-form-select size="sm" id="eksekutor" v-model="form.eksekutor"
                        :options="eksekutorOptions"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <h5><u>Inspection Detail</u></h5>
                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="date_label"
                      label="Inspector :" label-for="inspector">
                      <b-form-input id="inspector" v-model="form.inspector_name" type="text" size="sm"
                        :disabled="verify"></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="co_inspector_label" label="Co Inspector :" label-for="co_inspector">
                      <treeselect size="sm" id="co_inspector" v-model="form.co_inspector" :multiple="true"
                        :clearable="true" :async="true" valueFormat="label" :load-options="loadOptionsIssued"
                        :disabled="verify" @select="onSelect" />
                    </b-form-group>

                    <div v-if="inspection_plant" class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-1 variant="info">
                            <span class="svg-icon svg-icon-sm svg-icon-white">
                              <i class="flaticon-eye text-success"></i>
                            </span>
                            Detail Inspection Plan
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                          <b-card-body>
                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Mekanisme Kerusakan :</b></b-col>
                              <b-col>{{ inspection_plant[0].damage_mechanism }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Metode Inspeksi :</b></b-col>
                              <b-col>{{ inspection_plant[0].metode_inspeksi }}</b-col>
                            </b-row>

                            <b-row class="mb-2" v-if="inspection_plant[0].alasan_ditunda">
                              <b-col sm="6" class="text-sm-right"><b>Alasan Ditunda :</b></b-col>
                              <b-col>{{ inspection_plant[0].alasan_ditunda }}</b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>

                    <b-alert v-else show variant="warning">Belum ada Inspection Plant.</b-alert>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="file_label"
                      label="File :" label-for="file">
                      <b-form-file id="file" accept=".docx, .doc, .ppt, .pptx, .pdf" v-model="form.file"
                        placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                        size="sm"></b-form-file>
                    </b-form-group>

                    <h5><u>Lokasi</u></h5>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="pabrik_id_label" label="Pabrik :" label-for="pabrik_id">
                      <b-form-select size="sm" id="pabrik" v-model="form.pabrik_id" @change="choosePabrik"
                        :options="pabrik" :disabled="verify"></b-form-select>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="plant_id_label" label="Plant :" label-for="plant_id">
                      <b-form-select id="plant_id" v-model="form.plant_id" :options="plant"></b-form-select>
                    </b-form-group>

                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                      id="equipment_id_label" label="Equipment :" label-for="equipment_id">
                      <vue-suggestion id="equipment_id" :items="equipment" v-model="ItemEquipment" :setLabel="setLabel"
                        :itemTemplate="itemTemplate" @changed="inputChangeEquipment" @selected="chooseEquipment"
                        inputClasses="form-control" placeholder="Enter Text..." size="sm" :disabled="verify">
                      </vue-suggestion>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr />

                <b-button v-b-modal.modal-add-item class="mb-2" variant="outline-info" size="sm">
                  <span class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-plus"></i>
                  </span>
                  Add Item
                </b-button>

                <h4>Detail Item</h4>
                <b-row>
                  <b-col md="12">
                    <b-table head-variant="dark" bordered :items="items" :fields="fields" :current-page="currentPage"
                      stacked="sm" show-empty responsive @filtered="onFiltered" :busy="isBusy">
                      <template #cell(no)="data">
                        {{ (currentPage - 1) * 10 + data.index + 1 }}
                      </template>

                      <template #cell(image)="data">
                        {{ data.item.img.name }}
                      </template>

                      <template #cell(actions)="data">
                        <div class="
                                                                    														btn btn-icon btn-clean btn-lg btn-dropdown
                                                                    														mr-1
                                                                    													" @click="data.toggleDetails">
                          <span class="svg-icon svg-icon-sm svg-icon-success">
                            <i class="flaticon-eye text-success"></i>
                          </span>
                        </div>
                        <div class="
                                                                    														btn btn-icon btn-clean btn-lg btn-dropdown
                                                                    														mr-1
                                                                    													">
                          <span class="svg-icon svg-icon-xl svg-icon-danger" @click="toDelete(data.index)">
                            <i class="flaticon2-trash text-danger"></i>
                          </span>
                        </div>
                        <div class="
                                                                    														btn btn-icon btn-clean btn-lg btn-dropdown
                                                                    														mr-1
                                                                    													">
                          <span v-b-modal.modal-add-item class="svg-icon svg-icon-xl svg-icon-primary"
                            @click="toEdit(data.item, data.index)">
                            <i class="flaticon2-pen text-primary"></i>
                          </span>
                        </div>
                      </template>

                      <template #row-details="data">
                        <b-card>
                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Critically :</b></b-col>
                            <b-col>
                              <span v-if="data.item.critically == '1'">Good</span>
                              <span v-else-if="data.item.critically == '2'">Considerable</span>
                              <span v-else-if="data.item.critically == '3'">Warning</span>
                              <span v-else-if="data.item.critically == '4'">Bad</span>
                              <span v-else-if="data.item.critically == '5'">Breakdown</span>
                              <span v-else>{{ data.item.critically }}</span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Waktu Perbaikan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.onstream"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Status :</b></b-col>
                            <b-col>
                              <span v-html="data.item.status"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Hasil Pemeriksaan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.inspection_result"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Rekomendasi :</b></b-col>
                            <b-col>
                              <span v-html="data.item.recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Perbaikan yang dilakukan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.repair_taken"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Rekomendasi masa mendatang :</b></b-col>
                            <b-col>
                              <span v-html="data.item.future_recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Metode :</b></b-col>
                            <b-col>
                              <ul>
                                <li v-for="(row, index) in data.item.method" :key="index">
                                  {{ row }}
                                </li>
                              </ul>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Mekanisme Kerusakan :</b></b-col>
                            <b-col>
                              <ul>
                                <li v-for="(row, index) in data.item.damage_mechanism" :key="index">
                                  {{ row.label }}
                                </li>
                              </ul>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Prioritas :</b></b-col>
                            <b-col>
                              <span v-html="data.item.priority"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Image : </b></b-col>
                            <b-col>
                              <b-row>
                                <b-col lg="4" md="4" v-for="(img, index) in data.item.img" v-bind:key="index">
                                  <img :src="urlStorage + '/images/report/' + img" style="width: 100%" />
                                  <small>{{ data.item.image_keterangan[index] }}</small>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>

                          <span class="svg-icon svg-icon-sm svg-icon-dark" @click="data.toggleDetails">
                            <i class="flaticon-close text-dark"></i>
                          </span>
                        </b-card>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col md="5">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="10" align="fill" size="sm"
                      class="my-0"></b-pagination>
                  </b-col>
                  <b-col md="5">
                    <h4 class="float-right">
                      Showing
                      <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                      <span v-else>0</span>

                      of {{ totalRows }}
                    </h4>
                  </b-col>
                </b-row>

                <hr />

                <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
                  <h6>Peringatan :</h6>
                  <br />
                  <span v-for="(val, index) in errors" :key="index">
                    <!-- <h6>{{ index.replace(/_/g, ' ').toUpperCase() + ':' }}</h6> -->
                    <ul>
                      <li v-for="(arr, i) in val" :key="i">
                        {{ arr }}
                      </li>
                    </ul>
                    <br />
                  </span>
                </b-alert>

                <b-button variant="outline-dark" :disabled="loading" @click="$router.go(-1)">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button v-if="form.jabatan == 'inspector' && form.verify == 1" class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>

              <!-- Approval Button -->
              <b-button v-if="form.jabatan == 'kabag' && form.verify < 2" class="ml-3" @click="onApprove" variant="outline-primary" :disabled="loading">
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <!-- <strong>Loading...</strong> -->
                <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon2-list"></i>
                </span>
                Approve by Kabag
              </b-button>
              <b-button v-if="form.jabatan == 'manager' && form.verify == 2" class="ml-3" @click="onApproveManager" variant="outline-primary" :disabled="loading">
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <!-- <strong>Loading...</strong> -->
                <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon2-list"></i>
                </span>
                Approve by Manager
              </b-button>
              <b-button  v-if="form.verify == 2" class="ml-3" type="submit" variant="outline-primary" :disabled="isButtonDisabled">
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <!-- <strong>Loading...</strong> -->
                <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon2-list"></i>
                </span>
                Approved by Kabag
              </b-button>
              <b-button  v-if="form.verify == 3" class="ml-3" type="submit" variant="outline-primary" :disabled="isButtonDisabled">
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <!-- <strong>Loading...</strong> -->
                <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon2-list"></i>
                </span>
                Approved by Kabag & Manager
              </b-button>
                
              </b-form>
            </b-col>
          </b-row>
          <b-modal id="modal-add-item" ref="modal" :title="`${form.nomor_equipment} - ${ItemPart.name}`" size="xl"
            @hidden="resetModal" @ok="handleOk" :hideHeaderClose="true" :no-close-on-backdrop="true">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col>
                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Date :">
                    <b-form-input v-model="form.date" type="date" size="sm" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    label="Nomor Laporan :">
                    <b-form-input type="text" placeholder="Enter Text..." size="sm" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    label="Tujuan Laporan :">
                    <treeselect size="sm" v-model="form.issued_to" :multiple="true" :clearable="true" :async="true"
                      valueFormat="label" :load-options="loadOptionsIssued" disabled />
                  </b-form-group>

                  <h5><u>Lokasi</u></h5>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Pabrik :"
                    label-for="pabrik_id">
                    <b-form-input v-model="form.pabrik" type="text" disabled size="sm"></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" label="Plant :"
                    label-for="plant_id">
                    <b-form-select id="plant_id" v-model="form.plant_id" :options="plant" disabled
                      size="sm"></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    label="Equipment :">
                    <vue-suggestion :items="equipment" v-model="ItemEquipment" :setLabel="setLabel"
                      :itemTemplate="itemTemplate" inputClasses="form-control" size="sm" disabled>
                    </vue-suggestion>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="part_label"
                    label="Part :" label-for="part_id">
                    <vue-suggestion id="part_id" :items="part" v-model="ItemPart" :setLabel="setLabel"
                      :itemTemplate="itemTemplate" @changed="inputChangePart" @selected="itemSelectedPart"
                      inputClasses="form-control" placeholder="Enter Text...">
                    </vue-suggestion>
                  </b-form-group>

                  <h5><u>Inspection Detail</u></h5>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="method_label"
                    label="Metode :" label-for="method">
                    <treeselect size="sm" id="method" v-model="modalForm.method" :options="method" :clearable="true"
                      :multiple="true" placeholder="Pilih Metode Inspeksi..." />
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="damage_mechanism_label" label="Mekanisme Kerusakan :" label-for="damage_mechanism">
                    <treeselect size="sm" id="damage_mechanism" v-model="modalForm.damage_mechanism" :clearable="true"
                      :multiple="true" :async="true" valueFormat="label" :load-options="loadOptionsDamageMechanism"
                      @select="onSelect" />
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    label="Co Inspector :">
                    <treeselect size="sm" v-model="form.co_inspector" :multiple="true" :clearable="true" :async="true"
                      valueFormat="label" :load-options="loadOptionsIssued" disabled />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="inspection_result_label" label="Hasil Pemeriksaan :" label-for="inspection_result">
                    <vue-editor id="inspection_result" :editorToolbar="customToolbar"
                      v-model="modalForm.inspection_result" :disabled="verify"></vue-editor>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="critically_label" label="Critically :" label-for="critically">
                    <b-form-select size="sm" id="critically" v-model="modalForm.critically" :options="critically"
                      :disabled="verify"></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="img_label"
                    label="Image :" label-for="img" v-if="modalForm.image.length < 6">
                    <b-button class="mb-2" variant="outline-info" size="sm" @click="addImage">
                      <span class="svg-icon svg-icon-sm svg-icon-dark">
                        <i class="flaticon2-plus"></i>
                      </span>
                      Tambah Gambar
                    </b-button>
                  </b-form-group>

                  <div v-for="(img, index) in modalForm.image" v-bind:key="index">
                    <b-form-group v-if="modalForm.image_keterangan[index] != index" label-cols-sm="4" label-cols-lg="4"
                      content-cols-sm content-cols-lg="8" :id="`img_label_${index}`" :label="`Gambar ${index + 1} :`"
                      :label-for="`img_${index}`">
                      <b-form-file :id="`img_${index}`" accept=".jpg, .png, .jpeg, .pdf" v-model="modalForm.image[index]"
                        placeholder="Choose a photo or drop it here..."
                        drop-placeholder="Drop image here..."></b-form-file>
                      <b-form-input class="mt-1" v-model="modalForm.image_keterangan[index]"
                        :placeholder="`Keterangan Gambar ${index + 1}...`"></b-form-input>
                      <b-button v-if="modalForm.url[index]" class="mt-1" variant="outline-info" size="sm"
                        :href="urlStorage + '/images/report/' + modalForm.url[index]" target="_blank">
                        <span class="svg-icon svg-icon-sm svg-icon-info">
                          <i class="flaticon2-folder text-info"></i>
                        </span>
                        Lihat </b-button>&nbsp;
                      <b-button class="mt-1" variant="outline-danger" size="sm" @click="hapus(index)">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                        Hapus
                      </b-button>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="recomendation_label" label="Rekomendasi :" label-for="recomendation">
                    <vue-editor id="recomendation" :editorToolbar="customToolbar" v-model="modalForm.recomendation"
                      :disabled="verify"></vue-editor>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="priority_label" label="Prioritas :" label-for="priority">
                    <b-form-select size="sm" id="priority" v-model="modalForm.priority" :options="priority"
                      :disabled="verify"></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="onstream_label_radio" label="Waktu Perbaikan :" label-for="onstream_radio"
                    v-slot="{ ariaDescribedby }">
                    <b-form-radio-group id="onstream_radio" size="sm" v-model="modalForm.onstream" :options="onstream"
                      :aria-describedby="ariaDescribedby" name="radio-onstream_radio"
                      :stacked="true"></b-form-radio-group>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="repair_taken_label" label="Perbaikan yang dilakukan :" label-for="repair_taken">
                    <vue-editor size="sm" id="repair_taken" :editorToolbar="customToolbar"
                      v-model="modalForm.repair_taken"></vue-editor>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8" id="status_label"
                    label="Status :" label-for="status">
                    <b-form-select size="sm" id="status" v-model="modalForm.status" :options="status"></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8"
                    id="future_recomendation_label" label="Rekomendasi masa mendatang :" label-for="future_recomendation">
                    <vue-editor size="sm" id="future_recomendation" :editorToolbar="customToolbar"
                      v-model="modalForm.future_recomendation"></vue-editor>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert_modal">
                <h6>Peringatan :</h6>
                <br />
                <ul>
                  <li v-for="(val, index) in errors_modal" :key="index">
                    {{ val }}
                  </li>
                </ul>
              </b-alert>
            </form>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import { mapState } from "vuex";

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import itemTemplate from '@/view/pages/item-template.vue'

// import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  mixins: [validationMixin],
  name: 'editequipment',
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
          {
            list: 'check',
          },
        ],
        [
          {
            align: '',
          },
          {
            align: 'center',
          },
          {
            align: 'right',
          },
          {
            align: 'justify',
          },
        ],
      ],

      form: {
        date: null,
        report_no: null,
        issued_to: [],
        pabrik_id: null,
        plant_id: null,
        pabrik: '',
        plant: '',
        no_notifikasi: '',
        equipment_id: null,
        nomor_equipment: null,
        eksekutor: null,
        no_wo: null,
        co_inspector: [],
        file: null,
        verify: 1,
      },
      eksekutorOptions: [],
      equipment: [],
      pabrik: [],
      plant: [],
      issued: [],
      part: [],
      inspection_plant: null,
      modalForm: {
        id_report: null,
        part: null,
        critically: '1',
        onstream: "Onstream",
        status: null,
        inspection_result: '',
        recomendation: '',
        repair_taken: '',
        future_recomendation: '',
        method: [],
        damage_mechanism: [],
        url: [],
        image: [],
        image_keterangan: [],
        priority: null,
      },
      damage_mechanism: [],
      verify: false,
      critically: [
        { value: null, text: '-- Pilih Critically --' },
        { value: '1', text: 'Good' },
        { value: '2', text: 'Considerable' },
        { value: '3', text: 'Warning' },
        { value: '4', text: 'Bad' },
        { value: '5', text: 'Breakdown' },
        // { value: "Low", text: "Low" },
        // { value: "Medium", text: "Medium" },
        // { value: "High", text: "High" },
      ],
      onstream: [
        { value: 'Onstream', text: 'Onstream' },
        { value: 'Shutdown', text: 'Shutdown' },
      ],
      priority: [
        { value: null, text: '-- Pilih Priority --' },
        { value: 'Low', text: 'Low' },
        { value: 'Medium', text: 'Medium' },
        { value: 'High', text: 'High' },
        { value: 'Turn Arround', text: 'Turn Arround' },
      ],
      status: [
        { value: 'Belum dilakukan', text: 'Belum dilakukan' },
        { value: 'Sudah dilakukan', text: 'Sudah dilakukan' },
        {
          value: 'Dilakukan tidak sesuai rekomendasi',
          text: 'Dilakukan tidak sesuai rekomendasi',
        },
      ],
      method: [
        { id: 'Visual', label: 'Visual' },
        { id: 'Ultrasonic Thickness', label: 'Ultrasonic Thickness' },
        { id: 'Radiography', label: 'Radiography' },
        { id: 'Ultrasonic', label: 'Ultrasonic' },
        { id: 'Liquid Penetrant', label: 'Liquid Penetrant' },
        { id: 'Magnetic Particle', label: 'Magnetic Particle' },
        { id: 'Eddy Current', label: 'Eddy Current' },
        { id: 'Leak Test', label: 'Leak Test' },
        {
          id: 'Hydrostatic Pressure Test',
          label: 'Hydrostatic Pressure Test',
        },
        { id: 'Thermography', label: 'Thermography' },
        { id: 'Hardness Test', label: 'Hardness Test' },
      ],
      items: [],
      itemsDeleted: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'part', label: 'Part', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      totalPerPage: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      ItemEquipment: null,
      ItemPlant: null,
      ItemPart: { id: null, nama: null },
      itemTemplate,

      show: true,
      isBusy: false,
      loading: false,
      isButtonDisabled:true,
      errors: [],
      errors_modal: [],
      approval: false,
      showDismissibleAlert: false,
      showDismissibleAlert_modal: false,
      id: 0,
      urlStorage: '',
      modalIndex: null,
    }
  },
  validations: {
    form: {
      date: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    VueEditor,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: '/report/index' },
      { title: 'Edit Report' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id
    self.urlStorage = ApiService.urlStorage()
    self.loading = true

    ApiService.setHeader()

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/master/part/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var part = data.data
          for (let i = 0; i < part.length; i++) {
            self.part.push({
              value: part[i].id_part + '+' + part[i].nama_part,
              text: part[i].nama_part,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/report/create/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.ItemEquipment = {
            id: data.data[0].id_equipment,
            name: data.data[0].nama_equipment + ' (' + data.data[0].nomor_equipment + ')',
            plant_id: data.data[0].plant_id,
            pabrik_id: data.data[0].pabrik_id,
            pabrik: data.data[0].nama_pabrik,
            plant: data.data[0].nama_plant,
          }

          self.ItemPlant = {
            id: data.data[0].plant_id,
            name: data.data[0].nama_plant,
            pabrik: data.data[0].nama_pabrik,
            pabrik_id: data.data[0].pabrik_id,
          }

          self.form.date = data.data[0].date
          self.form.report_no = data.data[0].no_report
          self.form.no_notifikasi = data.data[0].no_notifikasi
          self.form.eksekutor = data.data[0].eksekutor
          self.form.no_wo = data.data[0].no_wo

          var issued_to = JSON.parse(data.data[0].issued_to)
          for (let i = 0; i < issued_to.length; i++) {
            var val = JSON.parse(issued_to[i])
            self.form.issued_to.push({
              id: val.id,
              label: val.label,
            })
          }

          var co_inspector = JSON.parse(data.data[0].co_inspector)
          if (co_inspector != null) {
            for (let i = 0; i < co_inspector.length; i++) {
              val = JSON.parse(co_inspector[i])
              self.form.co_inspector.push({
                id: val.id,
                label: val.label,
              })
            }
          }
          self.form.inspector_name = data.data[0].inspector_name
          self.form.pabrik_id = data.data[0].pabrik_id
          self.choosePabrik()
          self.form.pabrik = data.data[0].nama_pabrik
          self.form.plant = data.data[0].nama_plant
          self.form.plant_id = data.data[0].plant_id
          self.form.equipment_id = data.data[0].equipment_id
          self.form.nomor_equipment = data.data[0].nomor_equipment
          self.form.repair_taken = data.data[0].repair_taken
          self.form.future_recomendation = data.data[0].future_recomendation
          self.form.verify = data.data[0].verify
          self.form.jabatan = data.user
          // console.log(data.user)
          self.approval = data.approval

          if (data.data[0].verify == 1) {
            self.verify = false
          } else if (data.data[0].verify == 2) {
            self.verify = true
          }

          var item = data.data
          for (let i = 0; i < item.length; i++) {
            var img = JSON.parse(item[i].image)
            var image_keterangan = JSON.parse(item[i].image_keterangan)
            self.items.push({
              id_report: item[i].id_report,
              part_id: item[i].part_id,
              part: item[i].nama_part,
              status: item[i].status,
              inspection_result: item[i].inspection_result,
              critically: item[i].critically,
              onstream: item[i].onstream,
              recomendation: item[i].recomendation,
              repair_taken: item[i].repair_taken,
              future_recomendation: item[i].future_recomendation,
              method: item[i].method,
              damage_mechanism: JSON.parse(item[i].damage_mechanism),
              img: img,
              image_keterangan: image_keterangan,
              priority: item[i].priority,
              status_data: 'old',
            })
          }
          self.loading = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hapus(index) {
      this.isBusy = true
      setTimeout(() => {
        this.modalForm.image.splice(index, 1)
        this.modalForm.url.splice(index, 1)
        this.modalForm.image_keterangan.splice(index, 1)

        this.items[this.modalIndex].img.splice(index, 1)
        this.items[this.modalIndex].image_keterangan.splice(index, 1)
        this.isBusy = false
      }, 100)
    },
    toDelete(index) {
      this.isBusy = true
      // Hide the modal manually
      this.$nextTick(() => {
        this.items.splice(index, 1)
        this.isBusy = false
      })
    },
    toEdit(item, index) {
      this.ItemPart = {
        id: item.part_id,
        name: item.part,
      }
      this.modalForm.part = item.part
      if (item.status == null || item.status == 'null') {
        this.modalForm.status = 'Belum dilakukan'
      } else {
        this.modalForm.status = item.status
      }

      this.modalForm.critically = item.critically
      if (item.onstream == "null") {
        this.modalForm.onstream = "Onstream"
      } else {
        this.modalForm.onstream = item.onstream
      }

      this.modalForm.part_id = item.part_id
      this.modalForm.inspection_result = item.inspection_result
      this.modalForm.recomendation = item.recomendation
      this.modalForm.repair_taken = item.repair_taken
      this.modalForm.future_recomendation = item.future_recomendation
      this.modalForm.method = item.method
      // this.damage_mechanism = item.damage_mechanism
      this.modalForm.damage_mechanism = item.damage_mechanism
      this.modalForm.priority = item.priority
      this.modalForm.id_report = item.id_report

      if (item.img != null && item.img.length) {
        for (let i = 0; i < item.img.length; i++) {
          if (typeof item.img[i] === 'string') {
            this.modalForm.url.push(item.img[i])
            this.modalForm.image.push(null)
          } else {
            this.modalForm.image.push(item.img[i])
            this.modalForm.url.push(null)
          }
          this.modalForm.image_keterangan.push(item.image_keterangan[i])
        }
      }

      this.modalIndex = index
    },
    addImage() {
      this.modalForm.image.push(null)
      this.modalForm.image_keterangan.push(null)
    },
    resetModal() {
      this.ItemPart = { id: null, nama: null }
      this.modalForm = {
        id_report: null,
        part: null,
        critically: '1',
        onstream: "Onstream",
        status: null,
        inspection_result: '',
        recomendation: '',
        repair_taken: '',
        future_recomendation: '',
        method: [],
        damage_mechanism: [],
        url: [],
        image: [],
        image_keterangan: [],
        priority: "Low",
      }
    },
    async choosePabrik() {
      var self = this
      await ApiService.get(
        '/master/plant/all?pabrik_id=' + self.form.pabrik_id + '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plant --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      await ApiService.get('/master/eksekutor/all?pabrik_id=' + self.form.pabrik_id)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var eksekutor = data.data
            self.eksekutorOptions.splice(0, self.eksekutorOptions.length)
            self.eksekutorOptions.push({
              value: null,
              text: '-- Pilih Eksekutor --',
            })
            for (let i = 0; i < eksekutor.length; i++) {
              self.eksekutorOptions.push({
                value: eksekutor[i].nama_eksekutor,
                text: eksekutor[i].nama_eksekutor,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.isBusy = true
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Push the name to submitted names
      var part = null

      if (typeof this.modalForm.part === 'object' && this.modalForm.part !== null) {
        part = parseInt(this.modalForm.part.id)
      } else {
        part = this.modalForm.part
      }

      if (part != null) {
        if (this.modalIndex == null) {
          this.items.push({
            id_report: null,
            part: part,
            critically: this.modalForm.critically,
            onstream: this.modalForm.onstream,
            status: this.modalForm.status,
            inspection_result: this.modalForm.inspection_result,
            recomendation: this.modalForm.recomendation,
            repair_taken: this.modalForm.repair_taken,
            future_recomendation: this.modalForm.future_recomendation,
            method: this.modalForm.method,
            damage_mechanism: this.modalForm.damage_mechanism,
            img: this.modalForm.image,
            image_keterangan: this.modalForm.image_keterangan,
            priority: this.modalForm.priority,
            status_data: 'new',
          })
        } else {
          this.items[this.modalIndex].id_report = this.modalForm.id_report
          this.items[this.modalIndex].part = part
          this.items[this.modalIndex].critically = this.modalForm.critically
          this.items[this.modalIndex].onstream = this.modalForm.onstream
          this.items[this.modalIndex].status = this.modalForm.status
          this.items[this.modalIndex].inspection_result = this.modalForm.inspection_result
          this.items[this.modalIndex].recomendation = this.modalForm.recomendation
          this.items[this.modalIndex].repair_taken = this.modalForm.repair_taken
          this.items[this.modalIndex].future_recomendation = this.modalForm.future_recomendation
          this.items[this.modalIndex].method = this.modalForm.method
          this.items[this.modalIndex].damage_mechanism = this.modalForm.damage_mechanism
          if (this.items[this.modalIndex].img != null) {
            this.items[this.modalIndex].img.splice(0, this.items[this.modalIndex].img.length)
            if (this.items[this.modalIndex].image_keterangan) {
              this.items[this.modalIndex].image_keterangan.splice(
                0,
                this.items[this.modalIndex].image_keterangan.length
              )
            }
          }

          for (let i = 0; i < this.modalForm.image.length; i++) {
            if (this.items[this.modalIndex].img == null) {
              this.items[this.modalIndex].img = []
            }
            if (this.items[this.modalIndex].image_keterangan == null) {
              this.items[this.modalIndex].image_keterangan = []
            }
            if (this.modalForm.image[i]) {
              this.items[this.modalIndex].img.push(this.modalForm.image[i])
            } else {
              this.items[this.modalIndex].img.push(this.modalForm.url[i])
            }
            this.items[this.modalIndex].image_keterangan.push(this.modalForm.image_keterangan[i])
          }

          this.items[this.modalIndex].priority = this.modalForm.priority
          this.items[this.modalIndex].status_data = 'old'
        }

        this.resetModal()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-add-item')
          this.isBusy = false
        })
      } else {
        this.errors_modal.splice(0, this.errors_modal.length)

        if (!part) {
          this.errors_modal.push('Part Harus Terisi')
        }

        this.showDismissibleAlert_modal = true
      }
    },
    onSubmit() {
      // event.preventDefault()
      var self = this
      self.loading = true
      const date = self.form.date
      const report_no = self.form.report_no
      const no_notifikasi = self.form.no_notifikasi
      const issued_to = self.form.issued_to
      const pabrik_id = self.form.pabrik_id
      const plant_id = self.form.plant_id
      const equipment_id = self.form.equipment_id
      const eksekutor = self.form.eksekutor
      const no_wo = self.form.no_wo
      const co_inspector = self.form.co_inspector
      const file = self.form.file
      const verify = self.form.verify

      const dataku = new FormData()

      var items = self.items
      for (let i = 0; i < items.length; i++) {
        dataku.append('items[' + i + '][id_report]', items[i].id_report)
        dataku.append('items[' + i + '][part]', items[i].part)
        dataku.append('items[' + i + '][critically]', items[i].critically)
        dataku.append('items[' + i + '][onstream]', items[i].onstream)
        dataku.append('items[' + i + '][status]', items[i].status)
        dataku.append('items[' + i + '][inspection_result]', items[i].inspection_result)
        dataku.append('items[' + i + '][recomendation]', items[i].recomendation)
        dataku.append('items[' + i + '][repair_taken]', items[i].repair_taken)
        dataku.append('items[' + i + '][future_recomendation]', items[i].future_recomendation)
        if (items[i].damage_mechanism != null) {
          dataku.append(
            'items[' + i + '][damage_mechanism]',
            items[i].damage_mechanism.map(x => x.id).toString()
          )
        }

        var method = items[i].method
        for (let j = 0; j < method.length; j++) {
          dataku.append('items[' + i + '][method][' + j + ']', method[j])
        }

        dataku.append('items[' + i + '][priority]', items[i].priority)
        dataku.append('items[' + i + '][status_data]', items[i].status_data)
        if (items[i].img != null) {
          var img = items[i].img
          var img_keterangan = items[i].image_keterangan
          for (let j = 0; j < img.length; j++) {
            dataku.append('items[' + i + '][img][' + j + ']', img[j])
            dataku.append('items[' + i + '][img_keterangan][' + j + ']', img_keterangan[j])
          }
        }

      }

      for (let i = 0; i < issued_to.length; i++) {
        dataku.append('issued_to[' + i + ']', JSON.stringify(issued_to[i]))
      }

      if (co_inspector) {
        for (let i = 0; i < co_inspector.length; i++) {
          dataku.append('co_inspector[' + i + ']', JSON.stringify(co_inspector[i]))
        }
      }

      if (date) dataku.append('date', date)
      dataku.append('no_report', report_no)
      if (date) dataku.append('pabrik_id', pabrik_id)
      if (date) dataku.append('plant_id', plant_id)
      if (date) dataku.append('equipment_id', equipment_id)
      if (eksekutor) dataku.append('eksekutor', eksekutor)
      if (no_wo) dataku.append('no_wo', no_wo)
      if (file) {
        dataku.append('file', file)
      }
      dataku.append('report_type', 'report')
      dataku.append('no_notifikasi', no_notifikasi)
      dataku.append('verify', verify)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/report/create/update/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function (response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/report/index')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
          }
        },
      })
    },
    onApprove() {
      var self = this
      // self.loading = true;
      this.$swal({
        title: 'Konfirmasi Sebelum Menyetujui',
        icon: 'warning',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: 'Setujui Report',
        // denyButtonText: `Setujui Report`,
        cancelButtonText: `Batal`,
      }).then(result => {
        ApiService.get('/report/create/approval/' + self.id)
          .then(({ data }) => {
            if (data.status == 'ok') {
              self.$router.push('/report/index')
            } else {
              self.$bvToast.toast(data.message, {
                title: 'Error',
                variant: 'warning',
                toaster: 'b-toaster-top-center',
                solid: true,
              })
            }
          })
          .catch(response => {
            console.log(response)
          })
        self.loading = false
      })
    },onApproveManager() {
      var self = this
      // self.loading = true;
      this.$swal({
        title: 'Konfirmasi Sebelum Menyetujui, Report/Record ini akan di approve oleh Manager',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Setujui Report',
        cancelButtonText: `Batal`,
      }).then(result => {

        ApiService.get('/report/create/approvalManager/' + self.id)
          .then(({ data }) => {
            if (data.status == 'ok') {
              self.$router.push('/report/index')
            } else {
              self.$bvToast.toast(data.message, {
                title: 'Error',
                variant: 'warning',
                toaster: 'b-toaster-top-center',
                solid: true,
              })
            }
          })
          .catch(response => {
            console.log(response)
          })
        self.loading = false
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      if (this.form.plant_id && text) {
        url += '/' + this.form.plant_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    inputChangePlant(text) {
      // your search method
      var self = this
      var url = '/master/plant/showplant/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data

            self.plant.splice(0, self.plant.length)
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                id: plant[i].id_plant,
                name: plant[i].nama_plant,
                pabrik: plant[i].nama_pabrik,
                pabrik_id: plant[i].pabrik_id,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    onSelect() {
      this.$refs.report_no.focus()
    },
    loadOptionsIssued({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH && searchQuery.length >= 3) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/report/create/showissued/' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var issued = data.data

                self.issued.splice(0, self.issued.length)
                for (let i = 0; i < issued.length; i++) {
                  self.issued.push({
                    id: issued[i].nik,
                    label: issued[i].nama,
                  })
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          callback(null, self.issued)
        })
      }
    },
    loadOptionsDamageMechanism({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH && searchQuery.length >= 3) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/master/inspectionplant/showdamagemechanism/' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var damage_mechanism = data.data

                self.damage_mechanism.splice(0, self.damage_mechanism.length)
                for (let i = 0; i < damage_mechanism.length; i++) {
                  self.damage_mechanism.push({
                    id: damage_mechanism[i].id_damage_mechanism,
                    label: damage_mechanism[i].nama_damage_mechanism,
                  })
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          callback(null, self.damage_mechanism)
        })
      }
    },
    itemSelectedPart(item) {
      this.modalForm.part = item
    },
    inputChangePart(text) {
      // your search method
      var self = this
      ApiService.get('/master/part?page=1&nama_part=' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var part = data.data.data
            self.part.splice(0, self.part.length)

            for (let i = 0; i < part.length; i++) {
              self.part.push({
                id: part[i].id_part,
                name: part[i].nama_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.modalForm.part = text
    },
    chooseEquipment: function (value) {
      this.form.equipment_id = value.id
      this.form.pabrik_id = value.pabrik_id
      this.choosePabrik()
      this.form.plant_id = value.plant_id
      this.form.pabrik = value.pabrik
      this.form.plant = value.plant

      this.checkinspectionplant()
    },
    choosePlant: function (value) {
      this.form.plant_id = value.id
      this.form.plant = value.nama_plant
      this.form.pabrik_id = value.pabrik_id
    },
    checkinspectionplant() {
      var self = this
      if (self.form.date && self.form.equipment_id) {
        ApiService.get(
          '/inspectionplan/checkforreport/' + self.form.date + '/' + self.form.equipment_id
        )
          .then(({ data }) => {
            if (data.status == 'ok') {
              var inspection_plant = data.data
              // self.inspection_plant.splice(0, self.inspection_plant.length);
              // self.inspection_plant.push(inspection_plant);
              self.inspection_plant = inspection_plant
            }
          })
          .catch(response => {
            console.log(response)
          })
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
